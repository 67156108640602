.App {
  text-align: center;
  font-family: "League Spartan", arial, helvetica, sans-serif;
}

.ant-menu-horizontal {
  justify-content: center;
  align-items: center;
}

.App-header-menu {
  background: linear-gradient(-45deg, #d3fbf7, #fbdcd3, #fbf0d3);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.App-header-menu a:hover {
  color: #84624a;
}

.App-header .menu-btn {
  vertical-align: middle;
  float: right;
  padding: 1vh;
  min-height: 10vh;
}

.menu-btn:hover {
  cursor: pointer;
}

.App-header .menu-line:hover {
  cursor: pointer;
}

.App-header .menu-line {
  width: 35px;
  height: 5px;
  background-color: #839abf;
  margin: 6px 0;
}

.menu-img {
  max-height: 110px;
}

.banner {
  background-color: rgb(224, 188, 191, 0.5);
  border-radius: 4px;
  padding: 10px;
  text-align: -webkit-center;
}

.banner-quote {
  width: 100%;
}

button {
  border-radius: 5px;
  background-color: rgb(228, 176, 153, 0.6);
  color: #7f3e19;
}

button:hover {
  cursor: pointer;
}

button:disabled {
  cursor: revert;
}

.newsletter-btn {
  position: fixed;
  bottom: 120px;
  right: 30px;
  z-index: 99;
  padding: 15px;
}

.signup-form {
  height: 77vh;
  width: 100%;
}

.banner-citation {
  width: 100%;
}

.banner-header {
  text-align: left;
  margin-right: 10px;
  font-size: 34px;
  width: fit-content;
  display: inline-flex;
}

.banner-img {
  max-width: 150px;
  display: inline-flex;
  padding: 5px;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #000;
}

.ant-menu-horizontal > .ant-menu-item a {
  color: #839abf;
}

.ant-menu-horizontal > .ant-menu-item a:hover {
  color: #765039;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-body {
  /* background: linear-gradient(-45deg, rgb(233, 237, 241, 0.7), rgb(163, 177, 203, 0.7), rgb(242, 205, 157, 0.7));
  background-size: 400% 400%;
  animation: gradient 15s ease infinite; */
  background: url("https://wellcall-app-cdk.s3.amazonaws.com/r3counseling/Attachment-1.jpeg");
  background-size: cover;
  min-height: 86vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px;
  font-size: calc(10px + 2vmin);
  color: black;
  text-align: left;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.App-body-container {
  /* width: 100%; */
  background-color: rgb(255, 255, 255, 0.4);
  padding: 10px 40px;
  border-radius: 4px;
}

.body-img-right {
  float: right;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h4,
h2,
h3,
h5 {
  color: #839abf;
  font-family: LucidaHandwriting;
}

h1 {
  color: #839abf;
  font-family: BradleysPen;
}

.App-footer {
  background: linear-gradient(-45deg, #d3fbf7, #fbdcd3, #fbf0d3);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.footer-link,
a {
  color: #839abf;
  padding: 5px;
  font-size: 16px;
}

.footer-link:hover,
a:hover {
  color: #765039;
}

h1 {
  font-size: 34px;
  font-weight: bolder;
  margin: 10px 0px;
}

h2 {
  font-size: 30px;
  /* font-weight: 400; */
}

h3 {
  font-size: 26px;
  font-weight: 400;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 18px;
}

p,
ul,
ol {
  font-size: 16px;
}

.col-100 {
  width: 100%;
}

.col-50 {
  width: 50%;
}

.col-33 {
  width: 33%;
}

.col-66 {
  width: 66%;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

@media only screen and (max-width: 600px) {
  .body-img-right {
    width: 100%;
  }

  .banner-header {
    margin-right: none;
  }

  .menu-img {
    float: left;
    margin: 0px 0px 0px 10px;
    max-height: 110px;
  }
}

@media (max-width: 1024px) {
  .image-card h2 {
    font-size: 25px; /* Adjust as needed */
  }
}

@media (max-width: 480px) {
  .App-body {
    width: 100%;
  }
}

@import "~antd/dist/antd.css";

@font-face {
  font-family: "LucidaHandwriting";
  src: local("LucidaHandwriting"), url(./fonts/Lucida\ Handwriting\ Italic.ttf);
}

@font-face {
  font-family: "BradleysPen";
  src: local("BradleysPen"), url(./fonts/BRADHITC.TTF);
}
