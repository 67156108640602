.info-box-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(228, 176, 153);
  color: white;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  text-align: left;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 5px;
  overflow: auto;
}
.info-box-style p {
  font-size: 14px; /* Change this value to the size you want */
}
.info-box-style:hover {
  opacity: 1;
}
.custom-meta {
  padding: 10px !important; /* Change 10px to your desired padding */
}

@media (max-width: 480px) {
  .info-box-style {
    position: inherit;
    width: 270px;
    right: 30%;
    text-align: center;
  }
}
