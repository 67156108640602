/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  text-align: center;
}

.modal-container {
  display: grid;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 2% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: fit-content; /* Could be more or less, depending on screen size */
  height: 90%;
}

.modal-video {
  height: 100%;
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.psychotherapy-container {
  display: flex;
}

.psychotherapy-container-columns {
  width: 50%;
  display: inline-block;
}

.emdr-badge-container {
  float: right;
}

.emdr-badge-container img {
  max-height: 150px;
}

.gii-slide-img {
  max-height: 70vh;
}

@media only screen and (max-width: 600px) {
  .modal-video {
    max-width: 100%;
  }

  .psychotherapy-container-columns {
    width: 100%;
    display: inline-block;
  }

  .psychotherapy-container {
    display: block;
  }

  .gii-slide-img {
    width: 100%;
  }
}
