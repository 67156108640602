.events-container {
  text-align: center;
}

.events-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.events-col {
  flex: 1 1 100%;
  max-width: 300px;
  padding: 10px;
  cursor: pointer;
}

.events-col img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
  position: relative;
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 700px;
}

.modal-content img {
  width: 100%;
  height: auto;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}