.session-img-container {
  width: 100%; /* Take up the width of the page */
  overflow: hidden; /* Prevents overflow of child elements */
}

.session-img-container .mySlides {
  display: none; /* Use flexbox for alignment */
  justify-content: center; /* Center the images horizontally */
  align-items: center; /* Center the images vertically */
  width: 100%; /* Ensure the slide takes full width of the container */
  height: auto; /* Adjust the height automatically to maintain aspect ratio */
  max-height: 75vh; /* Limit the height to not exceed 75% of the viewport height */
}

.session-img-container .slide-img {
  width: 100%; /* Make the image responsive and take the full width of its parent */
  height: auto; /* Maintain the aspect ratio of the image */
}

.emdr-link {
  font-weight: 400;
  font-size: x-large;
}

.highlight-vid {
  width: 100%;
}

.free-consult-container {
  text-align: center;
  padding: 15px 0px;
}

.free-consult-button {
  padding: 5px;
}

.faq-question {
  font-weight: bold;
}

.consult-modal-none {
  display: none;
}

.consult-modal {
  z-index: 3;
  display: block;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.consult-modal-content {
  margin: auto;
  background-color: #fff;
  position: relative;
  padding: 0;
  outline: 0;
  width: 600px;
}

.transition-text {
  opacity: 0;
  transition: opacity 0.9s ease 0.203478s;
}

.transition-text.visible {
  opacity: 1;
}

.consult-container {
  padding: 0.01em 16px;
}

iframe {
  height: -webkit-fill-available;
  width: -webkit-fill-available;
}

.coming-soon-container {
  text-align: center;
}

@media only screen and (min-width: 600px) {
  .highlight-vid {
    height: 315px;
    width: 560px;
  }
}

@media only screen and (max-width: 600px) {
  .session-img {
    max-width: 100%;
    max-height: none;
  }
}

@media (max-width: 480px) {
  .heading {
    text-align: center;
  }
}
