.professional-accolades-list {
  text-align: left;
}

.journey-container {
  display: flex;
}

.journey-container-img-container {
  padding: 5px;
}

.journey-container-img {
  max-height: 500px;
  display: block;
  margin: 0;
  padding: 0;
}

.journey-container-list {
  width: 50%;
}

.info-box-style {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background-color: rgb(228, 176, 153);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  opacity: 0;
  transition: opacity 0.3s ease;
  padding: 20px;
}
.info-box-style p {
  font-size: 15px; /* Change this value to the size you want */
}

/* .image-container {
  position: relative;
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
} */

.image-container:hover .info-box-style {
  opacity: 1;
}

/* .journey-container-img:hover + .info-box-style {
  display: block;
} */

.journey-container-img-container:hover .info-box-style {
  opacity: 1;
}

.rounded-div {
  border-radius: 10px; /* Change this value to adjust the roundness */
}

@media only screen and (max-width: 600px) {
  .journey-container-img-container {
    text-align: center;
  }

  .journey-container {
    display: block;
  }

  .journey-container-img {
    width: 75%;
  }

  .journey-container-list {
    width: 75%;
  }
  /* .image-container {
    width: 75%;
} */
}
