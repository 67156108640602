.masthead-img {
  max-width: 100%;
}

.banner-container {
  font-size: 16px;
  font-weight: 400;
  font-style: italic;
}

.home-modal-content {
  max-height: fit-content;
  margin: 25vh 25vw;
  height: fit-content;
}

.home-img {
  width: 50vw;
  height: 50vh;
  background-color: transparent !important;
  background: transparent !important;
}

@media only screen and (max-width: 600px) {
  .close-container {
    float: none;
    display: block;
    text-align: center;
  }

  .close {
    float: none !important;
  }

  .home-modal-content {
    max-height: fit-content;
    margin: 25vh 0vw;
    height: fit-content;
    text-align: center;
  }

  .home-img {
    width: 90vw;
    margin: 0vh 0vw;
    background-color: transparent !important;
    background: transparent !important;
  }
}
